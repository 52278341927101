

import { mapGetters } from 'vuex'
const environment = process.env.NODE_ENV || 'development';
const envSet = require(`~/api/env.${environment}.js`)

export default {

	data() {
		return {
			cdnUrl : envSet.cdnUrl
		}
	},

	computed: {
		...mapGetters([
			'ui',
			'team',
			'projects',
			'archive'
		]),
	},

	head() {
		let _title = 'Garden Eight';
		let _description = this.$t.bind(this)("home.description");
		return {
			title: _title,
			meta: [
				{
					name: 'description',
					content: _description,
				}
			]
		}
	},

	async fetch ({ store, $http }) {
		if( !store.getters.projects.length ){
			const request = `${envSet.staticUrl}/db/projects.json`;
			const response = await $http.$get( request );
			store.dispatch( 'setProjects', response );
			// store.commit("UPDATE_PROJECTS", response );
			// await Promise.all([
			// 	store.dispatch('getProjects')
			// ]);
		}
	},

	mounted(){
		ROUTE.onMounted( this.$store.$device, {
			current : { type : 'index', slug : 'home' },
			prev : null,
			next : null
		});
	},

	transition : {
		mode: 'out-in',
		css: false,
		beforeEnter( el ){
			ROUTE.beforeEnter( el );
		},
		enter( el, done ){
			ROUTE.enter( el, done );
		},
		afterEnter( el ){
			ROUTE.afterEnter( el );
		},
		leave( el, done ){
			ROUTE.leave(el, done);
		},
	}

}
