
const environment = process.env.NODE_ENV || 'development';
const envSet = require(`~/api/env.${environment}.js`)
export default {
	data() {
		return {
			cdnUrl : envSet.cdnUrl,
			s3Url : envSet.s3Url
		}
	},
	props: ['project'],
}
